








































































































































































import Vue from "vue";
import apiClient from "@/services/apiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      userManager,
      dialog: false,
      form: false,
      ruleRequired: (value) => !!value || this.$t("REQUIRED"),
      headers: [
        { text: this.$t("PACKAGE_ID"), value: "package_id" },
        { text: this.$t("PACKAGE_BATCH_ID"), value: "package_batch_id" },
        { text: this.$t("TRUCK_ID"), value: "truck_id" },
        {
          text: this.$t("USER_NAME"),
          value: "sale_name",
          sortable: false,
        },
        {
          text: "Khách hàng",
          value: "customer_name",
          sortable: false,
        },
        { text: "Bảng giá", value: "pricing_item_id" },
        { text: this.$t("NAME"), value: "name" },
        { text: "Tiền vận chuyển", value: "fee_transport" },
        { text: "Lợi nhuận thanh toán", value: "profit" },
        { text: "Ủy thác nhập khẩu", value: "fee_trust" },
        { text: "Phí nội địa", value: "interior_cost" },
        { text: "Thuế VAT", value: "export_bill_vat" },
        { text: "Thuế nhập khẩu", value: "export_import_tax" },
        { text: "Ngày về VN", value: "truck_vn_time" },
        { text: "Ngày thanh lý", value: "xxx_time" },
        { text: "Trạng thái", value: "weight_done_status" },
        { text: this.$t("ACTIONS"), value: "actions", sortable: false },
      ],
      options: {},
      search: "",
      search_truck_id: "",
      loading: false,
      data: [],
      dataCount: 0,
      selectedItems: [],
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
    };
  },
  computed: {
    formTitle() {
      return this.$t("EDIT");
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async initialize() {
      const filters = [
        {
          key: "package_id",
          operator: "match",
          value: this.search,
        },
        {
          key: "liquidation_slip_id",
          operator: "equal_to",
          value: 0,
        },
        {
          key: "truck_id",
          operator: ">",
          value: 80,
        },
      ];
      if (this.search_truck_id) {
        filters.push({
          key: "truck_id",
          operator: "equal_to",
          value: this.search_truck_id,
        });
      }

      this.loading = true;
      const { items, count } = await apiClient.packageGetAll({
        options: this.options,
        filters,
        extra: {
          truck_vn: true,
          register: true,
        },
      });
      this.data = items;
      this.dataCount = count;
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      const result = await apiClient.packageUpdate(this.editedItem, [
        "fee_trust",
        "interior_cost",
        "export_bill_vat",
        "export_import_tax",
      ]);
      if (result) {
        this.close();
        await this.initialize();
      }
    },
    makeLiquidationSlip() {
      const ids = [];
      let customerId;
      let truckId;
      let saleId;
      for (const item of this.selectedItems) {
        ids.push(item.id);
        if (!customerId && item.customer_id) {
          customerId = item.customer_id;
        }
        if (!truckId && item.truck_id) {
          truckId = item.truck_id;
        }
        if (!saleId && item.sale_user_id) {
          saleId = item.sale_user_id;
        }
      }
      const idsStr = ids.join(",");
      const url = `/app/liquidation_slips/new?ids=${idsStr}&customer_id=${customerId}&truck_id=${truckId}&sale_user_id=${saleId}`;
      window.open(`${location.origin}/#${url}`, "_blank");
      // this.$router.push(url);
    },
  },
});
